<!-- 心理咨询配置 -- 咨询周期设置 -- 编辑 -->
<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="form-body" style="padding-top: 35rem;padding-left: 60rem">
      <h4>咨询基础配置</h4>
      <br>
      <el-form ref="form" :model="formData" label-width="100rem" :rules="rules">
        <div>
          <el-form-item label="咨询名称" prop="term_name">
            <el-input placeholder="请输入咨询名称" maxlength="50" v-model="formData.term_name"></el-input>
          </el-form-item>
          <el-form-item label="选择校区" prop="school_id">
            <p>{{dataCont.school_name}}</p>
          </el-form-item>
          <div class="flex flex-align">
            <el-form-item class="date-input-start" label="咨询周期" prop="start_at">
              <el-date-picker v-model="formData.start_at" :disabled="startCheck" :picker-options="startPicker" type="date" @change="startChange" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <p style="margin: 0 20rem 22rem">-</p>
            <el-form-item class="date-input" label-width="0" prop="end_at">
              <el-date-picker v-model="formData.end_at" :picker-options="freeTerm" type="date" placeholder="选择结束日期">
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
import moment from "moment";
import { getApi2 } from '@/api'
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      loading: false,
      dataCont: '',

      // 校区配置
      schoolConfig: [],

      // 表单数据
      formData: {
        term_name: '',
        school_id: '',
        start_at: '',
        end_at: ''
      },
      // 表单验证规则
      rules: {
        term_name: [{required: true, message: '请输入名称', trigger: 'change'}],
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        start_at: [{required: true, message: '请选择开始日期', trigger: 'change'}],
        end_at: [{required: true, message: '请选择结束日期', trigger: 'change'}],
      },
      startPicker:{},
      endPicker:{},
      left: '',
      right: '',
    }
  },
  created() {
    this.startPicker = {
      disabledDate(time) {
        return time.getTime() < moment().format('x')
      }
    }
    this.getSchoolConfig()
    this.getData()
    this.getInfo()
  },
  computed:{
    startCheck(){
      return moment(this.formData.start_at).format('x') < moment().format('x')
    },
    freeTerm() {
      let left = this.left ? moment(this.left).format('x') : ''
      let right = this.right ? moment(this.right).format('x') : ''
      return {
        disabledDate(time) {
          if(left && right) return time.getTime() < left || time.getTime() > right
          if(left) return time.getTime() < left
          if(right) return time.getTime() > right
          return false
        }
      }
    }
  },
  methods: {
    getInfo() {
      getApi2('/evaluation/counseling-setting/free-term', { id: this.$route.query.id }).then(res => {
        const { left, right } = res
        this.left = left
        this.right = right
      })
    },
    getSchoolConfig() {
      this.$_axios.get('site/school').then(res => {
        let data = res.data.data
        this.schoolConfig = data
      })
    },

    getData() {
      this.$_axios2.get('api/evaluation/counseling-setting/term-details?id=' + this.$route.query.id).then(res => {
        if (res.data.status === 0) {
          let data = res.data.data;
          this.dataCont = data;
          this.formData = {
            term_name: data.term_name,
            school_id: data.school_id.toString(),
            start_at: data.start_at,
            end_at: data.end_at,
          };
          this.endPicker = {
            disabledDate(time) {
              return time.getTime() < moment(data.start_at).format('x')
            }
          }
        }
      })
    },

    startChange(e){
      if(moment(e).format('x') > moment(this.formData.end_at).format('x')){
        this.formData.end_at = ''
      }
      this.endPicker = {
        disabledDate(time) {
          return time.getTime() < moment(e).format('x')
        }
      }
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let params = {
            id: this.$route.query.id,
            term_name: this.formData.term_name,
            school_id: this.formData.school_id,
            start_at: moment(this.formData.start_at).format('yyyy-MM-DD'),
            end_at: moment(this.formData.end_at).format('yyyy-MM-DD'),
          }
          this.$_axios2.put('api/evaluation/counseling-setting/term', {...params}).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '编辑成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1)
                }
              })
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form .date-input-start {
  width: 250rem !important;
}
::v-deep .el-form .date-input {
  width: 150rem !important;
}
</style>
